
import { devices, IDevice } from "@/api";
import { Actions } from "@/store/models";
import Vue from "vue";

export default Vue.extend({
  name: "EquipmentCard",
  props: {
    device: Object,
    focusedDeviceId: Number,
  },
  data() {
    return {};
  },
  computed: {
    focused: function (): boolean {
      return this.focusedDeviceId === (this.device as IDevice).id;
    },
    active: function (): boolean {
      return this.device.status === 1;
    },
  },
  methods: {
    async updateStatus() {
      try {
        // TODO
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    goToDeviceProfile() {
      this.$router.push(`/equipment/${this.device.id}`);
    },
    getSerialNumber(serialNumber: string): string {
      return serialNumber?.split("_")?.[1];
    },
  },
});
