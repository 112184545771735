
import { devices, IDevice, IListInfo } from "@/api";
import { Actions } from "@/store/models";
import Vue from "vue";
import EquipmentCard from "@/components/equipment/EquipmentCard.vue";

export default Vue.extend({
  name: "Equipment",
  components: {
    EquipmentCard,
  },
  data() {
    return {
      devices: [] as IDevice[],
      listInfo: {} as IListInfo,
      searchQuery: "",
    };
  },
  computed: {
    filteredDevices: function (): IDevice[] {
      return this.devices.filter((device: IDevice) => {
        if (
          device.device_serial_number
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          device.device_type?.name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        ) {
          return true;
        }
      });
    },
  },
  async beforeMount() {
    await this.getDevices();
    await this.getDevices();
    window.addEventListener("scroll", this.loadMore, false);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.loadMore, false);
  },
  methods: {
    async getDevices() {
      const start_index = this.listInfo.start_index
        ? this.listInfo.start_index + this.listInfo.page_limit!
        : 1;
      try {
        const response = await devices.getAll({ start_index });
        response.data.forEach((device) => {
          this.devices.push(device);
        });
        this.listInfo = response.list_info!;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async loadMore() {
      let bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight + 1 >
        document.documentElement.offsetHeight;
      // console.log(document.documentElement.scrollTop + window.innerHeight);
      // console.log("offset", document.documentElement.offsetHeight);
      // console.log(bottomOfWindow);
      if (bottomOfWindow) {
        this.getDevices();
      }
    },
  },
});
